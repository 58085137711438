import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Router from 'next/router';
import styled from '@emotion/styled';

import { Formik, Form as FormikForm } from 'formik';

import Link from '../../utils/link';
import { toast, TOAST } from '../../utils/toastify';
import { trackActiveUser } from '../../utils/tracking';
import { setUser as setUserAmplitude } from '../../utils/amplitude';

import Button from '../../atoms/button';
import InputField from '../../molecules/input-field';
import Typography from '../../atoms/typography';

import {
  login,
  getCurrentCart,
  getDefaultWishlist,
  getMinis,
  getUser,
  identifyKustomer
} from '../../pages/api';

import { setToken } from '../../store/modules/user/actions';
import { setUserProfile, toggleUserLoading } from '../../store/modules/profile/actions';
import { updateCart } from '../../store/modules/cart/actions';
import { setPetiteProfiles } from '../../store/modules/petites/actions';
import { updateWishlist } from '../../store/modules/lists/actions';

const StyledButton = styled(Button)`
  outline: 0;
  width: 100%;
`;

const ForgotPassword = styled(Typography)`
  color: ${(props) => props.theme.color.brand};
  display: block;
  text-decoration: underline;
  line-height: 4rem;
  text-align: center;
`;

const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 1rem;
`;

const LoginForm = (props) => (
  <Formik
    initialValues={{ email: '', password: '' }}
    initialStatus={{ button: props.buttonText }}
    validationSchema={
      Yup.object().shape({
        email: Yup.string().email('Please enter a valid Email Address').required('Email is required'),
        password: Yup.string().required('Password is required')
      })
    }
    onSubmit={(model, actions) => {
      actions.setStatus({ button: 'Logging In...' });

      login({ body: { user: model }, order_number: props.cart.number })
        .then(async (res) => {
          if (res.errors || !res.email || !res) {
            toast('Username or password is invalid.', { type: TOAST.TYPE.ERROR });
            return;
          }

          /* remove all previous data */
          global.document.cookie = 'maisonette_order_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          global.document.cookie = `maisonette_user_token=${res.spree_api_key}; Max-Age=${process.env.NEXT_PUBLIC_COOKIE_MAX_AGE}; Path=/; Secure; SameSite=Strict;`;


          /* kustomer */
          if (process.env.NEXT_PUBLIC_KUSTOMER_ACTIVE === 'true') { await identifyKustomer({ id: res.id }); }

          /* load user data */
          await Promise.all([
            getUser(),
            getCurrentCart(),
            getMinis(),
            getDefaultWishlist()
          ])
            .then(([user, cart, minis, wishlist]) => {
              const userData = user?.data ?? user;
              const cartData = cart?.data ?? cart;
              const minisData = minis?.data ?? minis;
              const wishlistData = wishlist?.data ?? wishlist;
              /* if cart exists, set maisonette_order_token */
              if (!cartData.error && cartData.token && cartData.token !== 'undefined') {
                props.updateCart(cartData);
                global.document.cookie = `maisonette_order_token=${cartData.token}; max-age=15768017; path=/;`;
              }

              /* create profile state */
              props.setToken('spree_api_key', res.spree_api_key);
              props.setUserProfile({ ...res, ...userData });
              trackActiveUser(userData);

              // set Amplitude user
              setUserAmplitude({ user: userData });

              /* create minis state */
              props.setPetiteProfiles(minisData);

              /* set default wishlist */
              props.updateWishlist(wishlistData.wished_products);

              props.toggleUserLoading(false);

              /* check if user is subscribed */
              if (userData.subscribed) global.document.cookie = 'subscribed_to_emails=1; path=/';
            })
            .finally(() => {
              toast('Login successful', { type: TOAST.TYPE.SUCCESS });

              const params = new URLSearchParams(global.window.location.search);
              const referrer = params.get('referrer');

              actions.setSubmitting(false);
              actions.setStatus({ button: props.buttonText });

              let redirectLink = referrer || props.redirect;

              if (redirectLink.charAt(0) !== '/') redirectLink = `/${redirectLink}`;

              Router.push(redirectLink)
                .then(() => global.window.scrollTo(0, 0));
            });
        })
        .finally(() => {
          actions.setSubmitting(false);
          actions.setStatus({ button: props.buttonText });
        });
    }}

    validateOnChange={false}
  >
    {({ status, isSubmitting }) => (
      <Form>
        <InputField
          label="Email Address (required)"
          name="email"
          id="login-email"
          placeholder="email@example.com"
          type="email"
          isWireframe
          autoComplete="username"
        />

        <InputField
          label="Password (required)"
          name="password"
          id="login-password"
          placeholder="••••••••"
          type="password"
          isWireframe
          autoComplete="current-password"
        />

        <Link href="/password/recover" passHref>
          <ForgotPassword element="a" like="dec-1">Forgot your password?</ForgotPassword>
        </Link>

        <StyledButton type="submit" disabled={isSubmitting} data-test-id="login">{status.button}</StyledButton>
      </Form>
    )}
  </Formik>
);

LoginForm.defaultProps = {
  buttonText: 'Log In',
  cart: {
    number: null,
    token: null
  },
  redirect: '/'
};

LoginForm.propTypes = {
  buttonText: PropTypes.string,
  cart: PropTypes.object,
  setToken: PropTypes.func.isRequired,
  setPetiteProfiles: PropTypes.func.isRequired,
  setUserProfile: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  updateWishlist: PropTypes.func.isRequired,
  toggleUserLoading: PropTypes.func.isRequired,
  redirect: PropTypes.string
};

const mapStateToProps = (state) => ({
  cart: state.cart
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (key, token) => dispatch(setToken(key, token)),
  setPetiteProfiles: (petites) => dispatch(setPetiteProfiles(petites)),
  setUserProfile: (user) => dispatch(setUserProfile(user)),
  updateCart: (cart) => dispatch(updateCart(cart)),
  updateWishlist: (wishlist) => dispatch(updateWishlist(wishlist)),
  toggleUserLoading: (loading) => dispatch(toggleUserLoading(loading))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
